<template>
<v-container>
    <v-row fill-height fluid v-if="emailValid">
      <v-col :cols="4"></v-col>
        <v-col :cols="4" align="center">
            <v-card>
                <v-card-title>
                    Scan Me
                </v-card-title>
                <v-card-text v-if="!fetchData">
                    <vue-qrcode :value="qrvalue" style="width:100%" :quality="1" :width="1000" />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col :cols="4"></v-col>
        <v-col :cols="12">
            <v-row fill-height>
                <v-col :cols="2" align="center">
                    <div class="text-h6"> User</div>
                </v-col>
                <v-col :cols="10" align="center">
                    <div class="text-h6"> : {{email}} </div>
                </v-col>
                <v-col :cols="12" align="center">
                    <v-divider></v-divider>
                </v-col>
                <v-col :cols="6" align="center">
                    <div class="text-h6"> Google Authenticator Secret</div>
                    <v-divider></v-divider>
                    <div><br></div>
                    <v-card align="center">
                        <v-card-text>
                            <div class="text-h6"> {{this.secret}}
                                <copy-to-clipboard :text="this.secret">
                                    <v-btn color="green" align="center">
                                        <v-icon dark>
                                            mdi-checkbox-multiple-blank-outline
                                        </v-icon>
                                    </v-btn>
                                </copy-to-clipboard>
                            </div>
                        </v-card-text>
                    </v-card>
                    <div><br></div>
                    <v-btn color="primary" @click="regenerateToken()">
                        (RE)Generate Secret
                    </v-btn>
                </v-col>
                <v-col :cols="6" align="center">
                    <div class="text-h6"> Validate OTP </div>
                    <v-divider></v-divider>
                    <div><br></div>
                    <v-card align="center">
                        <v-card-text>
                            <v-row>
                                <v-col :cols="8">
                                    <v-text-field label="OTP" v-model="otp" outlined type="number"></v-text-field>
                                </v-col>
                                <v-col :cols="4">
                                    <v-btn color="primary" @click="validate()">
                                        Validate
                                    </v-btn>
                                </v-col>
                                <v-col :cols="12" v-if="valid && showValid">
                                    <div class="green--text title">Valid OTP !!!</div>
                                </v-col>
                                <v-col :cols="12" v-if="!valid && showValid">
                                    <div class="red--text title">Invalid OTP !!!</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import CopyToClipboard from 'vue-copy-to-clipboard'

export default {
    components: {
        VueQrcode,
        CopyToClipboard,
    },
    data: () => ({
        qrvalue: "",
        otp: "",
        valid: false,
        emailValid: false,
        showValid: false,
        fetchData: true,
        id: "",
        email: "",
        secret: "",
    }),
    mounted() {
        this.fetchMe()
    },
    methods: {
        validate() {
            this.$http.get(this.$config.universeEndpoint + '/totp/validate?username=' + this.email + "&otp=" + this.otp)
                .then(response => {
                    if (response.status == 200) {
                        this.valid = true
                    }
                    this.showValid = true
                }).
            catch(() => {
                this.valid = false
                this.showValid = true
            })

        },
        generateSecret() {
            this.$http.post(this.$config.universeEndpoint + '/totp/')
                .then(response => {
                    this.secret = response.data.token

                    this.qrvalue = this.generateqrValue(this.email, this.secret)
                }).
            catch(e => {
                try {
                    this.$store.commit("setSnackbarText", this.snackbarText = e.response.data.message)
                } catch (e) {
                    this.$store.commit("setSnackbarText", "Error Get Data")
                }
                this.$store.commit("setSnackbarShow", true)
            })
        },
        regenerateToken() {
            this.$http.post(this.$config.universeEndpoint + '/totp/')
                .then(response => {
                    this.secret = response.data.token

                    this.qrvalue = this.generateqrValue(this.email, this.secret)
                }).
            catch(e => {
                try {
                    this.$store.commit("setSnackbarText", this.snackbarText = e.response.data.message)
                } catch (e) {
                    this.$store.commit("setSnackbarText", "Error Get Data")
                }
                this.$store.commit("setSnackbarShow", true)
            })
        },
        generateqrValue(email, secret) {
            return "otpauth://totp/universe.mokapos.io:" + email + "?algorithm=SHA1&digits=6&issuer=universe.mokapos.io&period=30&secret=" + secret
        },
        getQRValue() {
            this.$http.get(this.$config.universeEndpoint + '/totp/')
                .then(response => {
                    let data = response.data

                    if (data.token == "") {
                        this.generateSecret()
                    } else {
                        this.secret = data.token
                    }

                    this.qrvalue = this.generateqrValue(this.email, this.secret)

                    this.fetchData = false
                    this.emailValid = true
                }).
            catch(() => {
                this.$store.commit("setSnackbarText", "Your email (" + this.email + ") not registered, ask SRE to register your email")
                this.$store.commit("setSnackbarShow", true)
            })
        },
        fetchMe() {
            this.$http.get(this.$config.apiEndpoint + '/auth/validate/')
                .then(response => {
                    let data = response.data.data
                    this.email = data.email.split("@")[0]

                    this.getQRValue()
                }).
            catch(e => {
                try {
                    this.$store.commit("setSnackbarText", this.snackbarText = e.response.data.message)
                } catch (e) {
                    this.$store.commit("setSnackbarText", "Error Get Data")
                }
                this.$store.commit("setSnackbarShow", true)
            })
        },
    }
}
</script>
